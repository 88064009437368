<template lang="pug">
.song.card.text-start(:class="{removed: removed}")
    .card-body(:class="{long: cleanContent.length > 300, expanded}" style="position:relative")
        .regular-content
            .edit-post.m-2(v-if='UserStore.me.userid == song.userid && showEdit && (UserStore.me.superuser)')
                .form-floating.m-1
                    textarea.form-control(type="text" v-model="song.content" placeholder=" " rows="4" name="post" autocomplete="off" style="height: auto")
                    label(for="") Edit Post:
                .text-center(@click="editPost")
                    button.btn.btn-primary Save edit
            //.person
                p.card-title
                    UserName(:id="post.profileid" style="font-weight: bold")
                    span.to-user(v-if="post.toProfile && post.toProfile != post.profileid")
                        span.material-icons chevron_right
                        UserName(:id="post.toProfile" style="font-weight: bold")
                    span.small.text-muted(style="padding-left:5px") {{fromNow}}
            .title 
                p.title {{ song.title }}
            .content
                p.card-text(v-html='cleanContent')
            .read-more(v-if="cleanContent.length > 300")
                p.fw-bold.pointer(@click="expanded = !expanded") {{expanded ? "... read less" : "... read more"}}
            //Media(:content="post.content" :user="user" :post="post")
            //.media-subscribe-text
                p 
                    button.btn.btn-outline-secondary(@click="$router.push('/Me?subscribe=1')") Subscribe here! 
                    |  to have access to all the multimedia.
            .row.delete-post.text-end.small.text-muted(v-if='UserStore.me.userid == song.userid')
                p.m-1.pointer(@click="deletePost")
                    span.material-icons delete
                p.m-1.pointer(@click="showEditPost" v-if="(UserStore.me.superuser || UserStore.me.isGroup)")
                    span.material-icons edit
    .card-footer
        .row.text-center
            .col-3
                .reaction
                    button.btn.btn-light(@click="reaction") 
                        span.material-icons {{(song.reactions && Object.keys(song.reactions).length) ? (ILikeThis ? "volunteer_activism" : 'favorite') : 'favorite_border'}}
                        span.muted.number-reactions {{song.reactions ? Object.keys(song.reactions).length : 0}} 
            .col-3
                .comments-count
                    button.btn.btn-light(data-bs-toggle="collapse" :href="'#comments_' + song._id" role="button" aria-expanded="false" :aria-controls="'comments_' + song._id") 
                        span.material-icons forum
                        span.muted.number-reactions {{song.comments.length}} 
            .col-3
                .share
                    button.btn.btn-light 
                        span.material-icons ios_share
    //.card-footer.collapse(:id="'comments_' + post._id")
        .comments
            .new-comment.card
                .card-body.row
                    .col
                        input.form-control(type='text' name="new comment" placeholder="New comment" v-model="newCommentContent" :id="'NewComment' + post._id" autocomplete="off")
                    .col-auto.pointer
                        span.material-icons(@click="newComment") add_comment
            comment(v-for="(c, idx) in post.comments" :comment="c" :postid="post._id" :index="idx" @newCommentReaction="newCommentReaction")
                
</template>
    
<script>
import API from "../API.js";
import Comment from "./Comment.vue";
import UserName from "./UserName.vue";
import { UserStore } from "./../UserStore.js";

let chords = [];
const replaceTextInBrackets = (str) => {
    const regex = /(\[(.*?)\])/g;
    const matches = str.match(regex);
    if (!matches) {
        return str;
    }
    let p = str.split(/(\[.*?\])/g).map((item, index) => {
        console.log(item, index);
        if (item.match(/\[|\]/g)) {
            const dots = item.match(/\./g);
            const beats = dots ? dots.length : 0
            const content = item.replace(/\[|\]|\./g, '');
            chords.push([content, beats]);
            const key = chords.length - 1;
            return `<span class='chord' id='chord-${key}'>${content}</span>`;
        }
        return item;
    });
    return `<p>${p.join("")}<p>`;
};

export default {
    name: "Song",
    props: ["song"],
    data() {
        return {
            newCommentContent: "",
            userid: API.currentUserId(),
            expanded: false,
            UserStore,
            removed: false,
            postProfile: {},
            showEdit: false,
            chords: [],
            cleanContent: '',
        }
    },
    mounted() {
        //if (!this.post.profileid) return 0;
        //API.getUserProfile(this.post.profileid).then((p) => {
        //    this.postProfile = p;
        //});
        this.cleanContent = replaceTextInBrackets(this.song.content);
        this.chords = chords;
    },
    computed: {
        fromNow() {
            return window.moment(this.song.createdAt).fromNow();
        },
        ILikeThis() {
            //return this.song.reactions[this.user._id] ? true : false;
            return false;
        },
    },
    components: {
        Comment,
        UserName,
    },
    methods: {
        newComment() {
            if (!this.newCommentContent) return 0;
            this.$emit("newPostComment", this.song._id, this.index, this.newCommentContent);
            this.$emit("newPostBookmark", this.song._id, this.index);
            this.newCommentContent = "";
        },
        reaction() {
            this.$emit("newPostReaction", this.song._id, this.index);
        },
        newCommentReaction(postid, commentDate, commentIdx) {
            this.$emit("newCommentReaction", postid, commentDate, commentIdx, this.index);
        },
        deletePost() {
            API.deletePost(this.song._id).then(() => {
                this.removed = true;
            });
        },
        showEditPost() {
            this.showEdit = true;
        },
        editPost() {
            this.showEdit = false;
            API.updateSongContent(this.song).then(() => {
                this.showEdit = false;
            });
        }
    }
}
</script>
    
<style lang="sass">
.song
    margin-bottom: 20px
    overflow: hidden

    .number-reactions
        position: absolute
        padding-left: 3px
    .delete-post
        display: none
        position: absolute
        bottom: 10px
        right: 10px
        span
            &:hover
                color: #B05858 !important
    &:hover
        .delete-post
            display: block
    &.removed
        display: none
    .card-text
        white-space: pre
        line-height: 2.5em
        letter-spacing: 0.2em

        & .chord
            position: absolute
            transform: translate(0em, -1.3em)
            font-size: 0.7em
            letter-spacing: normal
    .long
        .content
            font-size: 1rem
            max-height: 200vh
            transition: max-height 0.7s ease-in-out  
        .news-content
            .content
                max-height: 200vh
    .expanded
        .content
            max-height: 100vh
    .comments
        .card
            margin-bottom: 5px 

    .ILikeThis
        color: red

    .media-subscribe-text
        display: none
    .course
        .media-content
            display: none
        .media-subscribe-text
            display: block
        &.subscriptor
            .media-content
                display: block
            .media-subscribe-text
                display: none
    .subscriptor
        .course
            .media-content
                display: block
            .media-subscribe-text
                display: none
</style>