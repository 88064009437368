<template lang="pug">
.groups-component.container
  .form-floating(style="margin-bottom:15px")
    input.form-control(placeholder="search" v-model="queryStr" v-on:input="onQueryChange")
    label 
      span.material-icons search
      | {{ $t("message.search") }}
  //.recent-groups.row.text-start
    .group.col-sm-6(v-for="g in groups" style="margin-bottom:15px" :key="g._id")
        GroupCard(:id="g._id" :key="g._id").m-1
  .card(v-for="song in songs" style="margin-bottom:15px" :key="song._id")
    Song(:song="song" :key="song._id")
    //.card-body 
        p.title {{song.title}}
        p.small {{ song.bpm }}
        p.content.song-content(v-html='contentWithChords(song.content)')
    //.card-bottom 
        button.btn Edit
  .new-group.card
    .card-body
        h4 New Song
        form#new-group-form(@submit.prevent)
            .row
                .col-6.form-floating           
                    input( placeholder='Name' v-model="title").form-control#NameInput
                    label(for="NameInput") {{ $t("message.title") }}
                .col-6.form-floating   
                    input(placeholder='pbm' v-model="bpm").form-control#LastNameInput
                    label(for="LastNameInput") bpm
            br
            .row
                .col-12.form-floating
                    textarea.form-control#DescriptionInput(placeholder="Add your song here" v-model="content")
                    label(for="DescriptionInput") Add your song here
            br
            .text-center
                button.btn.btn-primary(@click="register") {{ $t("message.register") }}
</template>

<script>
import API from "./../API.js";
import Song from "./../components/Song.vue"

export default {
    components:{
        Song
    },
    data(){
        return {
            title: '',
            content: '',
            author: '',
            bpm: '',
            songs: [],
            queryStr: '',
            timer: 0,
        }
    },
    methods:{
        register(){
            if(this.title + this.subtitle + this.description === '') return 0;
            API.newSong(this.title, this.content, this.bpm)
                .then(()=>{
                    
                });
        },
        onQueryChange(){
            console.log("Change to", this.queryStr)
            if(this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(()=>{
                if(!this.queryStr) {
                    return API.getRecentGroups().then((data)=>{
                        this.groups = data.groups;
                    })
                }
                API.searchGroups(this.queryStr).then((data)=>{
                    this.groups = data.groups;
                })
            }, 300)
        },
        contentWithChords(content){
            return content.replace(/\[(.*?)\]/g, '<span class="chord">$1</span>');
        }
    },
    mounted(){
        API.getRecentSongs().then((data)=>{
            this.songs = data.songs;
        })
    },
};
</script>

<style lang="sass">
.song-content
    white-space: pre
    line-height: 3em
    letter-spacing: 0.2em

    & .chord
        position: absolute,
        transform: translate(0em, -1.3em)
        font-size: 0.7em
        letter-spacing: normal
</style>